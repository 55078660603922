import { Accordion, Skeleton } from '@lanekassen/ui';
import purify from 'dompurify';
import { TrekkspillElementTekster } from '../epitekster/types';
import { useTeksterQuery } from '../hooks/queries';

interface TrekkspillElementWithLoaderProps {
  contentGuid: string;
}

function TrekkspillElementWithLoader({ contentGuid }: TrekkspillElementWithLoaderProps) {
  const { data: trekkspillelement, isLoading } = useTeksterQuery<TrekkspillElementTekster>(
    `TrekkspillElementBlock/${contentGuid}`,
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={40} />;
  }
  return (
    <Accordion>
      <Accordion.Header>{trekkspillelement?.trekkspillMellomoverskrift}</Accordion.Header>
      <Accordion.Content
        dangerouslySetInnerHTML={{
          __html: purify.sanitize(trekkspillelement?.trekkspillTekstfelt.value!),
        }}
      />
    </Accordion>
  );
}

export default TrekkspillElementWithLoader;
