import { Alert, Body, Box, Heading, Paper, Spinner, Stack } from '@lanekassen/ui';
import purify from 'dompurify';
import { useForsideteksterContext } from '../context/forsideteksterContext';
import SigneringsStatusMelding from '../components/SigneringsStatusMelding';
import ForsideAvtaler from '../components/ForsideAvtaler';
import { useHentAvtalerQuery } from '../hooks/queries';

function Forside() {
  const forsideTekster = useForsideteksterContext();
  const { data: foreldresigneringState, isLoading, isError, refetch: hentAvtalerPaNytt } = useHentAvtalerQuery();

  const fikkTekniskFeil = isError || foreldresigneringState?.harTjenesteFeilet;

  if (isLoading) {
    return <Spinner />;
  }

  const harIngenAvtaler =
    !foreldresigneringState?.agreementsForSigning?.length && !foreldresigneringState?.signedAgreements?.length;

  return (
    <Box
      sx={{
        // Styling kopiert fra Typography
        '& :is(h1,h2,h3,h4,h5,h6)': {
          fontFamily: '"Rubik","Helvetica","Arial",sans-serif',
          margin: 0,
          fontWeight: 500,
          lineHeight: 1.5,
          letterSpacing: 0,
        },
      }}
    >
      <Heading
        textAlign="center"
        level={1}
        padding={4}
        dangerouslySetInnerHTML={{
          __html: purify.sanitize(forsideTekster.sidetittel, { ALLOWED_TAGS: [] }),
        }}
      />
      <Paper sx={{ padding: 4, maxWidth: '596px', marginX: 'auto' }}>
        <Stack spacing={2}>
          {forsideTekster.globalNotabenemelding?.value && (
            <Alert severity="info">
              <Body
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(forsideTekster.globalNotabenemelding.value),
                }}
              />
            </Alert>
          )}
          {forsideTekster.globalFeilmelding?.value && (
            <Alert severity="error">
              <Body
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(forsideTekster.globalFeilmelding.value),
                }}
              />
            </Alert>
          )}
          <SigneringsStatusMelding refreshInnhold={hentAvtalerPaNytt} />
          <Body>{forsideTekster?.signeringUndertittel}</Body>
          {/* <Box border={"1px solid blue"} height="60px" paddingLeft={8} alignContent={"center"}>
              Det er en nyere avtale til signering.
            </Box> */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {harIngenAvtaler && !fikkTekniskFeil ? (
            <>
              <Body marginTop={2} isBold>
                {forsideTekster?.tittelIngenAvtaler}
              </Body>
              <Body
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(forsideTekster?.tekstIngenAvtaler.value),
                }}
              />
            </>
          ) : (
            <ForsideAvtaler fikkTekniskFeil={fikkTekniskFeil} foreldresigneringState={foreldresigneringState} />
          )}
        </Stack>
      </Paper>
    </Box>
  );
}

export default Forside;
